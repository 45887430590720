* {
    font-family: 'Hammersmith One', sans-serif;
}

body {
    margin: 0;
    min-height: 100vh;
    max-width:100vw;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1em);
}

.Header {
    display: flex;
    min-height: 20vh;
    min-width: 100vw;
    background-color: #B31942;
    color: white;
    align-items: center;
    justify-content: center;
}

.Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    color: black;
}

.Footer {
    font-family: 'Hammersmith One';
    display: flex;
    min-height: 20vh;
    min-width: 100vw;
    background-color: #0A3161;
    color: white;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.hidden {
    display: none;
}

ul {
    list-style-type: none;
}

.left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
